import React from 'react'
import Comment from './Comment.js'
import './comments.css'

export default function Comments({comments, article_id}) {
  
  if (!comments) {
    return (<></>)
  }
  
  return (
    <div className='comments'>
      {comments.map((comment) => {
        return (
        <Comment 
          key={comment.content + "-comment"}
          comment={comment}
          article_id={article_id} />)
      })}
    </div>
  )
}

import React from 'react'
import  './Comment.css'
import Comments from './Comments.js'
import Tag from "./Tag.js"


function EditTag({edits}) {
  if (!edits) {
    return('')
  }

  return(
    <span>
     (Edited {edits}x)
    </span>
  )
}

function OriginalContent({content}) {
  if (!content) {
    return('')
  }

  return(
    <>
    <br></br>
    <br></br>
    <div className='comment-header'>
      Original Comment
    </div>
    <div className='line-break' >
      {content}
    </div>
    </>

  )
}

export default function Comment({comment, article_id}) {

  let children
  if (comment.children) {
    children = (
    <Comments key={comment.content + '-child'} comments={comment.children} article_id={article_id} />
    )
  }

  let comment_styles = "comment"
  let status
  if (comment.status!=="displayed"){
    status = " (" + comment.status + ") "
  }
  if (comment.status === "deleted" || comment.status === "redeleted") {
    comment_styles = comment_styles + " deleted-comment"
  }
  if (comment.status === "restored") {
    comment_styles = comment_styles + " restored-comment"
  }
  
  return (
    <div className={comment_styles}>
      <div className='comment-header'>
        {comment.author} :: {comment.date}
      </div>
      <div className='comment-status'>
        <EditTag edits={comment.edits} /> {status}
      </div>
      <div className="tags">
      <Tag>
        <a href={"https://".concat(article_id, "#", comment.given_comment_id)}>
          HaD link
        </a>
      </Tag>
      <Tag>
        <a href={"/article/".concat(article_id, "#", comment.given_comment_id)}>
          Self link
        </a>
      </Tag>
      </div>
      <div className='line-break' >
        {comment.content}
      </div>
      <OriginalContent content={comment.original_content} />
      {children}
    </div>
  )
}

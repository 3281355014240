import React from 'react'
import './ArticleSummary.css'

export default function UnmemoizedArticleSummary({id, overview}) {
  const Counter = ({name, count}) => {
    if (!count) {count = 0}
    return (
      <div>
        {name} : {count}
      </div>
    )
  }

  return (
      
      <div className="article-summary">
              {console.log("ArticleSummary : ", overview)}
              <div className='header'><a href={"/article/"+id}>{overview.title}</a></div>
              <Counter name="deleted" count={overview.del_count}/>
              <Counter name="restored" count={overview.restored_count}/>
              <div className='uncut'>
                <a href={"article/" + id}>uncut</a>
              </div>
            </div>)
            
  
}

export const ArticleSummary = React.memo(UnmemoizedArticleSummary)
import React from 'react'
import ReactMarkdown from 'react-markdown'
import './Article.css'
import Comments from './Comments'
import Tag from './Tag.js'


export default class Article extends React.Component {
  constructor(props) {
    super(props);
    let article_id = this.props.match.params["*"]
    this.state = { article : {},
                   id : article_id,
                   finalized : 0}
  }

  loadArticle = () => {
    // Get remainder of url wildcat as the article ID.
    let filename = "/data/" + this.state.id + "article.json"

    // Load json file based on URL param.
    fetch(filename).then((r) => {
      return r.json()
    }).then((r) => {
      this.setState({article : r})
    })
  }

  componentDidMount(){
    this.loadArticle()
    setInterval(this.loadArticle, 3000)
  }

  componentWillUnmount(){
    clearInterval(this.loadArticle)
  }

  render() {

    if (!this.state.article.title) {
      return (<div>Loading...</div>)
    }

    return (
      <div className="article">
        <div className="title">
          <h1>{this.state.article.title}</h1>
          <h2>{this.state.article.author}</h2>
          <h4>{this.state.article.date}</h4>
        </div>
        <Tag>
        <a href={"https://".concat(this.state.id)}>
          HaD link
        </a>
        </Tag>
        <div className='article-content'>
          <ReactMarkdown className='line-break'>
            {this.state.article.content}
          </ReactMarkdown>
        </div>
        <Comments
          key="root-comments"
          comments={this.state.article.comments.children}
          article_id={this.state.id}/>
      </div>
      
    )
  }
}

import React from 'react'
import ArticleSummary from './ArticleSummary'
import "./Home.css"

export default class Home extends React.Component {

  constructor(props) {
    super(props)

    this.state = { overview : {}}
  }

  loadOverview = () => {
    fetch("/data/homepage.json").then((r) => {
      return r.json()
    }).then((r) => {
      this.setState({overview : r})
    })
  }

  componentDidMount() {
    this.loadOverview()
    setInterval(this.loadOverview, 3000)
  }
  
  componentWillUnmount(){
    clearInterval(this.loadArticle)
  }

  render() {
    //console.log("Home rendering : ", this.state.overview)
    if (!this.state.overview) {
      return (<div>Loading...</div>)
    }
    return (
      <div className='article-list'>
        <h1>Transparency Report: Hackaday.com</h1>
        Tracking (temporary and permanent) comment removal.

        <div>
          {Object.entries(this.state.overview).map(([key,value]) => {
            //console.log("Article data : ", this.state.overview[key])
            return (<ArticleSummary key={key} id={key} overview={this.state.overview[key]} />) })}
        </div>
      </div>
    )
  }
}

import React from 'react'
import { renderMatches } from 'react-router-dom'
import styles from './Tag.css'

export default class Tag extends React.Component {
  constructor(props) {
    super(props)
  }
 
  render() {
    return (
      <span className={[styles.tag]}>
        <span className="tag-content">
          {this.props.children}
        </span>
      </span>
    )
  }
}

import './App.css';
import Article from './Article.js'
import 'react-skeleton-css/styles/normalize.3.0.2.css'
import {BrowserRouter as Router, Routes, Route, useParams} from 'react-router-dom'
import Home from './Home.js'

function App() {

  // Use a Wrapper to pass URL params to Article.
  const Wrapper = (props) => {
    const params = useParams();
    return <Article  {...{...props, match: {params}} } />
  }

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/article/*" element={<Wrapper />} />
      </Routes>
    </Router>
  );
}

export default App;
